import { useMediaQuery } from "@mui/material";
import React from "react";
import { Carousel } from "react-bootstrap";

const AdsCarousel = ({ data = [], small = false, empty }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div className="ads-carousel-contianer pointer">
      {data.length === 0 && empty}
      {data.length > 0 && <Carousel controls={false} interval={5000} pause={false}>
        {data.map((ad, index) => (
          <Carousel.Item key={index} onClick={() => window.open((ad.hyperLink || `/events/${ad.uuid}`), "_blank")}>
            <div className="ads-carousel">
              {
                small ? <img src={isMobile ? ad.mobileImageUrl : ad.desktopImageUrl} alt="ad" />
                  : <img src={ad.mobileImageUrl} alt="ad" />
              }
              <img src={isMobile ? ad.mobileImageUrl : small ? ad.mobileImageUrl : ad.desktopImageUrl} alt="ad" />
            </div>
          </Carousel.Item>))
        }
      </Carousel>}
    </div>
  );
};

export default AdsCarousel;
