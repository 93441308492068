import React, { useEffect } from "react";
import ProfileCard from "./components/ProfileCard";
import AppInterface from "../../utils/commonComponents/AppInterface";
import { West } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import CustomButton from "../../utils/commonComponents/forms/CustomButton";
import { showModal } from "../home/HomeAction";
import { getDefaultImgUrl, modals } from "../../utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../utils/commonComponents/Modal";
import { getCompanyById } from "./ProfileAction";
import { toast } from "react-toastify";
import { CLEAR_ERRORS } from "./ActionTypes";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import PeopleCard from "./components/PeopleCard";

const ViewProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width: 768px)");
    const { modal, user } = useSelector((state) => state.HomeState);
    const params = useParams()
    const { error, company: current } = useSelector((state) => state.ProfileState);
    let company = current?.company
    useEffect(() => {
        if (params.id) dispatch(getCompanyById({ uuid: params.id }));
        else navigate(-1);
    }
        , [params]);

    useEffect(() => {
        if (error) {
            toast.error(error);
            navigate(-1);
            dispatch({ type: CLEAR_ERRORS })
        }
    }, [error, dispatch, navigate]);

    useEffect(() => {
        if (company && user && user?.user?.uuid === company?.user?.uuid) {
            navigate("/profile");
        }
    }, [company, user, navigate]);


    const downloadFile = async ({ url, fileName }) => {
        // The URL of the file you want to download
        const fileUrl = url;

        try {
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = fileName || "download";
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(blobUrl);
            window.open(fileUrl, "_blank");
        } catch (error) {
            window.open(fileUrl, "_blank");
        }
    };

    return (
        <>
            {modal === modals.BOOK_MEEETING_MODAL && (
                <BasicModal
                    open={modals.BOOK_MEEETING_MODAL}
                    handleClose={() => dispatch(showModal(null))}
                    closeOnBackdropClick
                    className={"edit-member-modal"}
                    hideCloseButton={isMobile}
                    data={{ uuid: company.user.uuid }}
                />
            )}
            <AppInterface
                header={
                    <div className="networking-header flex-row gap-12 align-center">
                        <div className="pointer" onClick={() => navigate(-1)}>
                            <West></West>
                        </div>
                        <div>
                            <h1 className="f-s-16 manrope-bold">{company?.companyName}</h1>
                        </div>
                    </div>
                }
                hideNavigation
            >
                <div className="my-profile-container ">
                    {!isMobile && (
                        <div className="networking-header flex-row gap-12 align-center mb-3">
                            <div className="pointer" onClick={() => navigate(-1)}>
                                <West sx={{ fontSize: "24px" }}></West>
                            </div>
                            <div>
                                <h1 className="f-s-24 manrope-bold">{company?.companyName}</h1>
                            </div>
                        </div>
                    )}
                    <ProfileCard
                        img={company?.profileImageUrl ? company?.profileImageUrl : getDefaultImgUrl(company?.companyName)}
                        heading={company?.companyName}
                        text={`${company?.contactPerson} | ${company?.designation}`}
                        phone={company?.phone ? company?.phone : <p className="blur">1234567890</p>}
                        email={company?.user.email ? company?.user.email : <p className="blur">xyz@gmail.com</p>}
                        tip={company?.phone ? "" : "Request a meeting to view the details*"}
                        background={company?.bannerUrl}
                    ></ProfileCard>

                    <div className="company-details-container">
                        <Row>
                            <Col md="7">
                                <Row>
                                    <Col className="my-1">
                                        <h3 className="f-s-16 manrope-medium">About Company</h3>
                                        <p className="f-s-14 my-2 manrope-regular">
                                            {company?.companyDescription}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6" md="3" className="my-2">
                                        <h3 className="f-s-16 manrope-medium">Primary Contact</h3>
                                        <p className="f-s-14 my-2 manrope-regular">
                                            {company?.contactPerson}
                                        </p>
                                    </Col>
                                    <Col xs="6" md="3" className="my-2">
                                        <h3 className="f-s-16 manrope-medium">
                                        Incorportaion  Date
                                        </h3>
                                        <p className="f-s-14 my-2 manrope-regular">
                                            {dayjs(company?.incorporationDate).format("DD-MM-YYYY")}
                                        </p>
                                    </Col>
                                    <Col xs="6" md="3" className="my-2">
                                        <h3 className="f-s-16 manrope-medium">Designation</h3>
                                        <p className="f-s-14 my-2 manrope-regular">
                                            {company?.designation}
                                        </p>
                                    </Col>
                                    <Col xs="6" md="3" className="my-2">
                                        <h3 className="f-s-16 manrope-medium">GST No.</h3>
                                        <p className="f-s-14 my-2 manrope-regular">{company?.gstNo}</p>
                                    </Col>
                                    <Col xs="6" md="3" className="my-2">
                                        <h3 className="f-s-16 manrope-medium">Company Reg. No.</h3>
                                        <p className="f-s-14 my-2 manrope-regular">
                                            {company?.companyRegistrationNumber}
                                        </p>
                                    </Col>
                                    <Col xs="6" md="3" className="my-2">
                                        <h3 className="f-s-16 manrope-medium">Type of Company</h3>
                                        <p className="f-s-14 my-2 manrope-regular">
                                            {company?.companyType?.companyTypeName}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="3"></Col>
                            <Col md="2">
                                <div className="company-profile-buttons flex-col gap-25">
                                    {<CustomButton
                                        onClick={() =>
                                            dispatch(showModal(modals.BOOK_MEEETING_MODAL))
                                        }
                                        className={"primary-btn"}
                                    >
                                        Book a Meeting
                                    </CustomButton>}
                                    {company?.brochureUrl && (
                                        <CustomButton
                                            onClick={() =>
                                                downloadFile({
                                                    url: company?.brochureUrl,
                                                    fileName: company?.companyName,
                                                })
                                            }
                                            className={"primary-btn bg-grey-F2F black"}
                                        >
                                            Brochure
                                        </CustomButton>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="associated-members">
                        {company?.members?.length > 0 && (
                            <Row>
                                <Col>
                                    <h3 className="f-s-16 manrope-bold mb-4">People</h3>
                                </Col>
                            </Row>
                        )}
                        <Row className="flex-col gap-12">
                            {company?.members?.map((member) => {
                                return (
                                    <Col md="5" sm="12">
                                        <PeopleCard
                                            uuid={member.uuid}
                                            name={`${member.firstName || "New IPMMI User"} ${member.lastName || ""}`}
                                            img={member.profileImageUrl}
                                        ></PeopleCard>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
            </AppInterface >
        </>
    );
};

export default ViewProfile;
