import React, { useEffect } from "react";
import ProfileCard from "./components/ProfileCard";
import AppInterface from "../../utils/commonComponents/AppInterface";
import { West } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import CustomButton from "../../utils/commonComponents/forms/CustomButton";
import { showModal } from "../home/HomeAction";
import { getDefaultImgUrl, modals } from "../../utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../utils/commonComponents/Modal";
import { getMemberbyId } from "./ProfileAction";
import { toast } from "react-toastify";
import { CLEAR_ERRORS } from "./ActionTypes";
import { Col, Row } from "react-bootstrap";

const ViewProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { modal, user } = useSelector((state) => state.HomeState);
  const params = useParams();
  const { error, member } = useSelector((state) => state.ProfileState);
  useEffect(() => {
    if (params.id) dispatch(getMemberbyId({ uuid: params.id }));
    else navigate(-1);
  }, [params]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      navigate(-1);
      dispatch({ type: CLEAR_ERRORS });
    }
  }, [error, dispatch, navigate]);

  return (
    <>
      {modal === modals.BOOK_MEEETING_MODAL && (
        <BasicModal
          open={modals.BOOK_MEEETING_MODAL}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          data={{ uuid: member?.user?.uuid }}
          className={"edit-member-modal"}
          hideCloseButton={isMobile}
        />
      )}
      <AppInterface
        header={
          <div className="networking-header flex-row gap-12 align-center">
            <div className="pointer" onClick={() => navigate(-1)}>
              <West></West>
            </div>
            <div>
              <h1 className="f-s-16 manrope-bold">
                {member?.firstName || "New user"} {member?.lastName || ""}
              </h1>
            </div>
          </div>
        }
        hideNavigation
      >
        <div className="my-profile-container ">
          {!isMobile && (
            <div className="networking-header flex-row gap-12 align-center mb-3">
              <div className="pointer" onClick={() => navigate(-1)}>
                <West sx={{ fontSize: "24px" }}></West>
              </div>
              <div>
                <h1 className="f-s-24 manrope-bold">
                  {member?.firstName || "New user"} {member?.lastName || ""}
                </h1>
              </div>
            </div>
          )}
          {member?.user && (
            <ProfileCard
              img={
                member?.profileImageUrl
                  ? member?.profileImageUrl
                  : getDefaultImgUrl(
                      member?.firstName || member?.company?.companyName
                    )
              }
              heading={`${member?.firstName || "New user"} ${
                member?.lastName || ""
              }`}
              text={`${member?.designation} | ${member?.company?.companyName}`}
              phone={
                member?.phone ? (
                  member?.phone
                ) : (
                  <p className="blur">1234567890</p>
                )
              }
              email={
                member?.user?.email ? (
                  member?.user?.email
                ) : (
                  <p className="blur">xyz@email.com</p>
                )
              }
              background={member?.bannerUrl || false}
              tip={
                member?.phone ? "" : "Request a meeting to view the details*"
              }
            ></ProfileCard>
          )}
          <Row>
            <Col md="7" className="my-1">
              {member?.aboutSection && (
                <>
                  <h3 className="f-s-16 manrope-medium">About</h3>
                  <p className="f-s-14 my-2 manrope-regular">
                    {member?.aboutSection}
                  </p>
                </>
              )}
            </Col>
            <Col md="3"></Col>
            <Col md="2">
              <div className="my-profile-btn flex-row gap-10">
                {" "}
                <CustomButton
                  className={"primary-btn"}
                  onClick={() =>
                    dispatch(showModal(modals.BOOK_MEEETING_MODAL))
                  }
                >
                  Book a Meeting
                </CustomButton>
              </div>
            </Col>
          </Row>
        </div>
      </AppInterface>
    </>
  );
};

export default ViewProfile;
