import { toast } from "react-toastify"
import {
    GET_HOME_BANNERS, CONNECT_WEBSOCKET,
    RECEIVE_WEBSOCKET_MESSAGE,
    WEBSOCKET_ERROR,
    UPDATE_NOTIFICATION_LIST,
    DISCONNECT_WEBSOCKET, GET_HOME_EVENTS, GET_MEMBER_RECOMMENDATIONS, GET_USER_PROFILE, SET_MODAL,
    GET_PAST_NOTIFICATIONS,
    REFRESH_WEB_SOCKET
} from "./ActionTypes"
import postLoginInstance from "../../utils/PostLoginAxios"

export const showModal = (val) => (dispatch) => {
    dispatch({ type: SET_MODAL, payload: val })
}


export const getHomeBanners = () => async (dispatch) => {
    try {
        const { data } = await postLoginInstance.get("/banner/current")
        dispatch({ type: GET_HOME_BANNERS, payload: data })
    }
    catch (err) {
        toast.error(err.response.data.message || "Something went wrong")
    }
}

export const getHomeEvents = () => async (dispatch) => {
    try {
        const { data } = await postLoginInstance.get("/event/current-events")
        dispatch({ type: GET_HOME_EVENTS, payload: data })
    }
    catch (err) {
        toast.error(err.response.data.message || "Something went wrong")
    }
}


export const getUserProfile = () => async (dispatch) => {
    try {
        const { data } = await postLoginInstance.get("/users")
        dispatch({ type: GET_USER_PROFILE, payload: data.list })
    }
    catch (err) {
        toast.error(err.response.data.message || "Something went wrong")
    }
}

export const getMemberRecommendations = () => async (dispatch) => {
    try {
        const { data } = await postLoginInstance.get("/members/member-reccomendations")
        dispatch({ type: GET_MEMBER_RECOMMENDATIONS, payload: data.list })
    }
    catch (err) {
        toast.error(err.response.data.message || "Something went wrong")
    }
}


export const connectWebSocket = () => ({
    type: CONNECT_WEBSOCKET,
});

export const receiveWebSocketMessage = (data) => ({
    type: RECEIVE_WEBSOCKET_MESSAGE,
    payload: data,
});

export const webSocketError = (error) => ({
    type: WEBSOCKET_ERROR,
    payload: { error },
});
export const disconnectWebSocket = () => ({
    type: DISCONNECT_WEBSOCKET,
});

export const readNotification = ({ notificationUuid }) => async (dispatch, getState) => {
    const uuidArray = Array.isArray(notificationUuid) ? notificationUuid : [notificationUuid];

    await Promise.all(
        uuidArray.map(uuid =>
            postLoginInstance.patch(`/notifications`, {}, { params: { notificationUuid: uuid } })
        )
    );
    const { notifications } = getState().HomeState;

    const updatedNotifications = notifications.messages.filter(
        (notification) => !uuidArray.includes(notification.uuid)
    );

    dispatch(updateNotificationList(updatedNotifications));
};


export const updateNotificationList = (updatedNotifications) => {
    return {
        type: UPDATE_NOTIFICATION_LIST,
        payload: updatedNotifications,
    };
};

export const getPastNotifications = () => async (dispatch) => {
    try {
        const all = await postLoginInstance.get("/notifications/user-notifications")
        const sent = await postLoginInstance.get("/notifications/sent-notifications")
        const received = await postLoginInstance.get("/notifications/recevied-notifications")
        const data = {
            sent: sent.data.list,
            received: received.data.list,
            all: all.data.list
        }
        dispatch({ type: GET_PAST_NOTIFICATIONS, payload: data })
    }
    catch (err) {
        toast.error(err.response.data.message || "Something went wrong")
    }
}


export const refreshWebSocketNotifications = () => async (dispatch, getState) => {
    dispatch({ type: REFRESH_WEB_SOCKET, payload: !getState().HomeState.notifications.refresh });
}
