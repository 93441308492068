import React from "react";
import CustomButton from "../../../utils/commonComponents/forms/CustomButton";
import { useNavigate } from "react-router-dom";
import { getDefaultImgUrl } from "../../../utils/Constant";

const PeopleCard = ({ uuid, name, img }) => {
  const navigate = useNavigate();
  return (
    <div className="people-card-main flex-row justify-space-between align-center round-10">
      <div className="flex-row gap-3 align-center">
        <div className="people-imgae-container">
          <img src={img || getDefaultImgUrl(name)} alt="profile" />
        </div>
        <h6 className="f-s-14 marope-regular">{name}</h6>
      </div>
      <div>
        <CustomButton
          className={"primary-btn"}
          onClick={() => navigate(`/profile/${uuid}`)}
        >
          View Profile
        </CustomButton>
      </div>
    </div>
  );
};

export default PeopleCard;
