import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Email, Phone } from "@mui/icons-material";
import bg from "../../../assets/images/common-bg-mobile.png";
import postLoginInstance from "../../../utils/PostLoginAxios";
import { toast } from "react-toastify";
import { getUserProfile } from "../../home/HomeAction";
import { useDispatch } from "react-redux";
import ImageCropper from "../../../utils/commonComponents/ImageCropper";
import BasicModal from "./../../../utils/commonComponents/Modal";

const CompanyProfile = ({
  img,
  heading,
  text,
  background,
  phone,
  email,
  tip,
  edit = false,
}) => {
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(null); // For cropping
  const [croppedImage, setCroppedImage] = useState(background || bg); // Store cropped image
  const inputRef = useRef();
  // Trigger file input click
  const handleClick = () => {
    inputRef && inputRef.current.click();
  };

  // Handle image selection
  const handleImageChange = (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!validImageTypes.includes(file.type)) {
      return toast.warn("Please upload a valid image file (JPEG, PNG, GIF)");
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageSrc(reader.result); // Load the image into the cropper
    };
    reader.readAsDataURL(file);
  };

  // Handle image cropping
  const handleCropComplete = async (croppedDataUrl) => {
    setImageSrc(null); // Close cropper
    setCroppedImage(croppedDataUrl); // Set cropped image

    // Convert base64/URL to Blob
    const response = await fetch(croppedDataUrl);
    const blob = await response.blob();

    // Convert Blob to File object
    const file = new File([blob], "bannerImage.jpg", { type: blob.type });

    // Upload cropped image to the server
    const formData = new FormData();
    formData.append("bannerImage", file);

    try {
      await postLoginInstance.patch("users/edit-banner", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(getUserProfile());
      toast.success("Banner image updated successfully!");
    } catch (error) {
      console.error("Error uploading cropped image:", error);
      toast.error("Failed to upload image.");
    }
  };

  const handleCancelCrop = () => {
    setImageSrc(null); // Close cropper without saving
  };

  return (
    <>
      {imageSrc && (
        <BasicModal open={imageSrc} handleClose={() => setImageSrc("")}>
          <ImageCropper
            imageSrc={imageSrc}
            aspect={4}
            onCropComplete={handleCropComplete}
            onCancel={handleCancelCrop}
          />
        </BasicModal>
      )}
      <div className="home-company-profile">
        <div className="company-bg">
          <img
            src={edit ? croppedImage : background ? background : bg}
            alt="profile banner"
          />
          {edit && (
            <div className="edit-icon" onClick={handleClick}>
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5 18.5H21.5V20H0.5V18.5ZM18.05 5.75C18.65 5.15 18.65 4.25 18.05 3.65L15.35 0.95C14.75 0.35 13.85 0.35 13.25 0.95L2 12.2V17H6.8L18.05 5.75ZM14.3 2L17 4.7L14.75 6.95L12.05 4.25L14.3 2ZM3.5 15.5V12.8L11 5.3L13.7 8L6.2 15.5H3.5Z"
                  fill="white"
                />
              </svg>
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                className="d-none"
                onChange={handleImageChange}
              />
            </div>
          )}
        </div>
        <div className="company-main px-0">
          <Row>
            <Col md="12" className="relative media">
              <div className="company-logo">
                <img src={img} alt="company logo" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="flex-row justify-space-between align-center p-md-4 mt-5 main-content">
                <div className="profile">
                  <h4 className="f-s-16 manrope-medium">{heading}</h4>
                  <p className="f-s-14 manrope-regular">{text}</p>
                </div>
                <Row className="gap-25">
                  <Col>
                    <div className="display-chip flex-row gap-2 align-center">
                      <Phone />
                      <p
                        onClick={() => {
                          if (phone === "12345678") return;
                          window.open(`tel:${phone}`);
                        }}
                        className="f-s-16 manrope-regular pointer"
                      >
                        {phone}
                      </p>
                    </div>
                  </Col>

                  <Col>
                    <div className="display-chip flex-row gap-2 align-center">
                      <Email />
                      <p
                        onClick={() => {
                          if (email === "xyz@gmail.com") return;
                          window.open(`mailto:${email}`);
                        }}
                        className="f-s-16 manrope-regular pointer"
                      >
                        {email}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              {tip && (
                <div className="text-right profile-tip mb-2 me-5">
                  <p className="error blue-0E1">{tip}</p>
                </div>
              )}
              <hr />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
