import React, { useEffect } from "react";
import AppInterface from "../../utils/commonComponents/AppInterface";
import { West } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../../utils/commonComponents/forms/CustomButton";
import { getEventDetails } from "./EventsAction";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

const ViewEvents = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!params.id) {
      navigate(-1);
    }
    if (params.id) {
      dispatch(getEventDetails({ uuid: params.id }));
    }
  }, [params, navigate, dispatch]);

  const { events } = useSelector((state) => state.EventsState);
  return (
    <AppInterface
      header={
        <div className="networking-header flex-row gap-12 align-center">
          <div className="pointer" onClick={() => navigate("/events")}>
            <West></West>
          </div>
          <div>
            <h1 className="f-s-16 manrope-bold">
              {events.details.data.eventName}
            </h1>
          </div>
        </div>
      }
    >
      <div className="events-details-main-container ">
        {!isMobile && (
          <div className="networking-header flex-row gap-12 align-center mb-3">
            <div className="pointer" onClick={() => navigate("/events")}>
              <West sx={{ fontSize: "24px" }}></West>
            </div>
            <div>
              <h1 className="f-s-24 manrope-bold">
                {events.details.data.eventName}
              </h1>
            </div>
          </div>
        )}

        <div>
          <div className="event-main-img">
            <img
              src={
                isMobile
                  ? events.details.data.mobileImageUrl
                  : events.details.data.desktopImageUrl
              }
              alt="img"
            />
          </div>
          <Row className="mt-5">
            <Col md="7">
              <Row className="my-sm-4 mt-0">
                <Row>
                  <Col md="10">
                    <Row>
                      <div className="flex-row gap-10">
                        <div className="p-4">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.4"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.1838 6.35223H18.8621V4.56445H11.1838V6.35223ZM9.43707 4.63787V4.64677C8.83768 4.70128 8.24473 4.82032 7.66786 4.99943V4.99275C7.65071 4.99997 7.63457 5.00552 7.61907 5.01084C7.59873 5.01784 7.57951 5.02444 7.56064 5.03392C7.51453 5.04504 7.4695 5.06173 7.42661 5.08287C7.30008 5.12403 7.17356 5.17298 7.05454 5.22304C7.02291 5.23528 6.99128 5.24946 6.95965 5.26365C6.92801 5.27783 6.89638 5.29201 6.86475 5.30425C6.82133 5.32483 6.77602 5.34736 6.73072 5.36989C6.68542 5.39242 6.64012 5.41495 6.59669 5.43553L6.38224 5.55901C6.34259 5.57923 6.30589 5.60295 6.26844 5.62715C6.24614 5.64156 6.22357 5.65614 6.19996 5.67026C5.87721 5.8783 5.57806 6.12193 5.30785 6.39894L5.29177 6.41563C5.25628 6.457 5.22094 6.49714 5.18608 6.53674C5.1272 6.60362 5.06968 6.66894 5.01513 6.73603L4.99905 6.7516C4.12195 7.96199 3.68233 9.45384 3.75846 10.9657V11.4452H5.49013V10.9613C5.49013 8.85421 6.16136 7.54258 7.655 6.87064C8.22543 6.62923 8.82803 6.48015 9.44136 6.42564H9.45744V4.64566L9.43707 4.63787ZM24.9975 6.72935L25.005 6.74493C25.881 7.95643 26.3185 9.44939 26.2413 10.9613V11.4452H24.5107V10.949C24.5471 10.0568 24.3681 9.16904 23.9885 8.36694C23.6239 7.68053 23.0406 7.14765 22.3362 6.8584C21.7754 6.61699 21.1814 6.46681 20.5755 6.41341V4.64232C21.1749 4.69795 21.7679 4.81587 22.3458 4.99498V4.98608C22.3783 5.00082 22.4031 5.00958 22.4456 5.02461L22.453 5.02724C22.4728 5.03422 22.4912 5.0412 22.5094 5.0481C22.5347 5.05769 22.5596 5.06713 22.5871 5.07619C22.7056 5.11509 22.8165 5.16093 22.9306 5.2081L22.9506 5.21637C23.0032 5.23485 23.0528 5.25778 23.0975 5.27844C23.1119 5.28513 23.1259 5.29159 23.1393 5.29758C23.2336 5.33874 23.3301 5.38769 23.4073 5.42885C23.4588 5.45557 23.509 5.48416 23.556 5.51098C23.5814 5.52548 23.606 5.53946 23.6293 5.55234C23.6677 5.57355 23.7011 5.59625 23.7332 5.61808C23.7567 5.63402 23.7795 5.6495 23.803 5.66359C24.1268 5.87274 24.4292 6.11748 24.7026 6.39227L24.7123 6.40896C24.813 6.51019 24.9085 6.61699 24.9975 6.72935Z"
                              fill="#D9D9D9"
                            />
                            <path
                              d="M20.625 3.39616V7.33558C20.625 7.83193 20.2222 8.23396 19.725 8.23396C19.2277 8.23396 18.825 7.83193 18.825 7.33558V3.40514C18.8205 2.90879 19.221 2.50339 19.7182 2.50002C20.2155 2.49665 20.6216 2.89531 20.625 3.39167V3.39616Z"
                              fill="#D9D9D9"
                            />
                            <path
                              d="M11.175 3.41037V7.34005C11.175 7.83856 10.7718 8.24275 10.2744 8.24275C9.77712 8.24275 9.375 7.83856 9.375 7.34005V3.41037C9.375 2.91186 9.77712 2.50879 10.2744 2.50879C10.7718 2.50879 11.175 2.91186 11.175 3.41037Z"
                              fill="#D9D9D9"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.75 21.1381V11.4453H26.25V21.1381C26.25 25.3649 23.8895 27.5004 19.2114 27.5004H10.7779C6.11052 27.5004 3.75 25.3649 3.75 21.1381ZM8.82513 16.8339C8.82513 17.4203 9.2865 17.8961 9.85517 17.8961C10.4346 17.8961 10.8959 17.4093 10.8852 16.8228C10.8852 16.2364 10.4238 15.7606 9.8659 15.7606H9.85517C9.2865 15.7606 8.82513 16.2475 8.82513 16.8339ZM13.9646 16.8339C13.9646 17.4203 14.4367 17.8961 15.0054 17.8961C15.574 17.8961 16.0354 17.4203 16.0354 16.8228C16.0354 16.2475 15.574 15.7717 15.0054 15.7606H14.9946C14.426 15.7717 13.9646 16.2475 13.9646 16.8339ZM20.1449 17.8961C19.5762 17.8961 19.1148 17.4203 19.1148 16.8339C19.1148 16.2475 19.5655 15.7717 20.1449 15.7717C20.7135 15.7717 21.1749 16.2475 21.1749 16.8339C21.1749 17.4203 20.7135 17.8961 20.1449 17.8961ZM20.1449 22.4327C19.5762 22.4327 19.1148 21.9569 19.1041 21.3705C19.1041 20.784 19.5655 20.3082 20.1341 20.3082H20.1449C20.7135 20.3082 21.1749 20.784 21.1749 21.3705C21.1749 21.9569 20.7135 22.4327 20.1449 22.4327ZM15.0054 22.4327C14.4367 22.4327 13.9646 21.9569 13.9646 21.3705C13.9646 20.784 14.426 20.3083 14.9946 20.2972H15.0054C15.574 20.3083 16.0247 20.784 16.0354 21.3594C16.0354 21.9569 15.574 22.4327 15.0054 22.4327ZM9.85515 22.4327C9.28648 22.4327 8.82511 21.9569 8.82511 21.3705C8.81438 20.784 9.27575 20.2972 9.85515 20.2972C10.4238 20.2972 10.8852 20.773 10.8852 21.3594C10.8852 21.9459 10.4238 22.4327 9.85515 22.4327Z"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </div>
                        <div className="flex-col justify-center">
                          <div className="f-s-16 manrope-medium">
                            {dayjs(
                              events?.details?.data?.startDate?.split("T")[0]
                            ).format("DD MMMM, YYYY")}
                          </div>
                          <div className="f-s-12 manrope-semibold grey-808">
                            {dayjs(events.details.data.startDate).format(
                              "dddd h:mmA"
                            )}
                          </div>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md="10">
                    <Row>
                      <div className="flex-row gap-10">
                        <div className="p-4">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.6645 3.67096C13.3956 2.08409 16.7525 2.11183 19.458 3.74362C22.1368 5.40864 23.765 8.38022 23.7498 11.5768C23.6874 14.7524 21.9416 17.7375 19.7593 20.0451C18.4998 21.383 17.0908 22.566 15.5611 23.57C15.4035 23.6611 15.2309 23.7221 15.0519 23.75C14.8795 23.7427 14.7116 23.6917 14.5634 23.6018C12.228 22.0932 10.1792 20.1675 8.51542 17.9175C7.12324 16.0392 6.33229 13.77 6.25 11.418C6.24819 8.21531 7.93346 5.25784 10.6645 3.67096ZM12.2427 12.7434C12.7021 13.876 13.7865 14.6147 14.9895 14.6147C15.7776 14.6204 16.5352 14.3047 17.0935 13.738C17.6518 13.1713 17.9644 12.4007 17.9616 11.5979C17.9658 10.3725 17.2443 9.26533 16.1341 8.79341C15.0238 8.32148 13.7438 8.57788 12.8916 9.44287C12.0395 10.3079 11.7833 11.6108 12.2427 12.7434Z"
                              fill="#D9D9D9"
                            />
                            <path
                              opacity="0.4"
                              d="M15 27.5C18.4518 27.5 21.25 26.9404 21.25 26.25C21.25 25.5596 18.4518 25 15 25C11.5482 25 8.75 25.5596 8.75 26.25C8.75 26.9404 11.5482 27.5 15 27.5Z"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </div>
                        <div
                          className="flex-col justify-center pointer"
                          onClick={() =>
                            window.open(
                              events.details.data.locationUrl,
                              "_blank"
                            )
                          }
                        >
                          <div className="f-s-16 manrope-medium">
                            {events.details.data.venue}
                          </div>
                          <div className="f-s-12 manrope-semibold grey-808">
                            Click to view on map
                          </div>
                          <div className="f-s-12 manrope-semibold grey-808"></div>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col md="2"></Col>
            <Col md="3">
              {!isMobile && (
                <div className="register-btn">
                  <CustomButton
                    className={"primary-btn flex-row gap-12 align-center"}
                    onClick={() =>
                      window.open(
                        events.details.data.registrationLink,
                        "_blank"
                      )
                    }
                  >
                    Register{" "}
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 6.5C0 6.05127 0.363769 5.6875 0.8125 5.6875H9.87179L5.91095 1.72666C5.58722 1.40293 5.59186 0.876646 5.92125 0.558678C6.24252 0.248547 6.75305 0.253051 7.0688 0.568802L12.9356 6.43565C12.9712 6.47119 12.9712 6.52881 12.9356 6.56435L7.07001 12.43C6.75521 12.7448 6.2448 12.7448 5.93001 12.43C5.61623 12.1162 5.61509 11.6078 5.92746 11.2926L9.87179 7.3125H0.8125C0.363769 7.3125 0 6.94873 0 6.5Z"
                        fill="white"
                      />
                    </svg>
                  </CustomButton>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="10">
              <div className="f-s-18 manrope-medium">About Event</div>
              <p className="f-s-16 manrope-regular my-3 event-description">
                {events.details.data.eventDescription}
              </p>
              <div>
                {" "}
                {isMobile && (
                  <div className="register-btn my-5 pt-5">
                    <CustomButton
                      className={"primary-btn flex-row gap-12 align-center"}
                      onClick={() =>
                        window.open(
                          events.details.data.registrationLink,
                          "_blank"
                        )
                      }
                    >
                      Register{" "}
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 6.5C0 6.05127 0.363769 5.6875 0.8125 5.6875H9.87179L5.91095 1.72666C5.58722 1.40293 5.59186 0.876646 5.92125 0.558678C6.24252 0.248547 6.75305 0.253051 7.0688 0.568802L12.9356 6.43565C12.9712 6.47119 12.9712 6.52881 12.9356 6.56435L7.07001 12.43C6.75521 12.7448 6.2448 12.7448 5.93001 12.43C5.61623 12.1162 5.61509 11.6078 5.92746 11.2926L9.87179 7.3125H0.8125C0.363769 7.3125 0 6.94873 0 6.5Z"
                          fill="white"
                        />
                      </svg>
                    </CustomButton>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </AppInterface>
  );
};

export default ViewEvents;
