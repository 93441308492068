import HomeCurrentSvg from "../assets/images/home-filled.svg"
import NotificationSvg from "../assets/images/notification.svg"
import NetworkingSvg from "../assets/images/networking.svg"
import InviteSvg from "../assets/images/invite.svg"
import CalenderSvg from "../assets/images/Calendar_light.svg"
import CalenderCurrentSvg from "../assets/images/Calendar_fill.svg"
import ProfileSvg from "../assets/images/User_cicrle_light.svg"
import ProfileCurrentSvg from "../assets/images/User_cicrle_active.svg"
import NetworkingCurrentSvg from "../assets/images/networking-active.svg"
import HomeSvg from "../assets/images/Home_light.svg"
import InviteCurrentSvg from "../assets/images/User_add_light.svg"
import NotificationFill from "../assets/images/Notification-fill.svg"



export const modals = {
    HOME_WELCOME_MODAL: "HOME_WELCOME_MODAL",
    HOME_NOTIFICATION_MODAL: "HOME_NOTIFICATION_MODAL",
    INVITE_REFER_MODAL: "INVITE_REFER_MODAL",
    INVITE_CO_MEMBER_MODAL: "INVITE_CO_MEMBER_MODAL",
    EDIT_MEMBER_PROFILE_MODAL: "EDIT_MEMBER_PROFILE_MODAL",
    EDIT_COMPANY_PROFILE_MODAL: "EDIT_COMPANY_PROFILE_MODAL",
    EDIT_PROFILE_OPTIONS_MODAL: "EDIT_PROFILE_OPTIONS_MODAL",
    EDIT_PASSWORD_MODAL: "EDIT_PASSWORD_MODAL",
    BOOK_MEEETING_MODAL: "BOOK_MEEETING_MODAL",
    HOME_ADS_MODAL: "HOME_ADS_MODAL",
}

export const API_URL = process.env.REACT_APP_API_BASE_URL;
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;
export const phoneRegex = /^[0-9]{10}$/;


export const routes = [
    {
        name: "Home", route: "/", svg: HomeSvg, currentSvg: HomeCurrentSvg
    },
    {
        name: "Events", route: "/events", svg: CalenderSvg, currentSvg: CalenderCurrentSvg
    },
    {
        name: "Networking", route: "/networking", svg: NetworkingSvg, currentSvg: NetworkingCurrentSvg
    },
    {
        name: "Invite", route: "/invite", svg: InviteSvg, currentSvg: InviteCurrentSvg
    },
    {
        name: "Notifications", route: "/notifications", svg: NotificationSvg, currentSvg: NotificationFill,
        action: modals.HOME_NOTIFICATION_MODAL
    },
]

export const getDefaultImgUrl = (name) => {
    return `https://ui-avatars.com/api/?name=${name}&background=random`
}

export const mobileRoutes = [
    {
        name: "Home", route: "/", svg: HomeSvg, currentSvg: HomeCurrentSvg
    },
    {
        name: "Events", route: "/events", svg: CalenderSvg, currentSvg: CalenderCurrentSvg
    },
    {
        name: "Networking", route: "/networking", svg: NetworkingSvg, currentSvg: NetworkingCurrentSvg
    },
    {
        name: "Invite", route: "/invite", svg: InviteSvg, currentSvg: InviteCurrentSvg
    },
    {
        name: "Profile", route: "/profile", svg: ProfileSvg, currentSvg: ProfileCurrentSvg
    },
]