import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect } from "react";
import emailIcon from "../../../assets/images/mail.svg";
import passwordIcon from "../../../assets/images/password.svg";
import passwordEyeIcon from "../../../assets/images/password-open-eye.svg";
import { showModal } from "../../home/HomeAction";
import { emailRegex, modals } from "../../../utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../OnboardingAction";
import paswordEyeCloseIcon from "../../../assets/images/ph_eye-closed.svg";

const Login = ({ setScreen }) => {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [error, setError] = React.useState({ email: false, password: false });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const params = new URLSearchParams(useLocation().search);
    useEffect(() => {
        params.get("token") && setScreen("forgotPassword")
    }, [params])
    const { loading, token } = useSelector((state) => state.OnboardingState);

    useEffect(() => {
        if (token) {
            navigate("/");
        }
    }, [token]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Enter" && email.length > 0 && password.length > 0) {
                handleLogin();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [email, password]);


    const handleLogin = () => {
        if (email.length === 0 || password.length === 0) {
            toast.error("Please fill all the fields");
            return;
        }
        if (!email.match(emailRegex)) {
            setError({ ...error, email: true });
            return;
        }
        if (password.length < 6) {
            setError({ ...error, password: true });
            return;
        }
        dispatch(login({ email, password }));
    };

    useEffect(() => {
        if (email.match(emailRegex)) setError((err) => ({ ...err, email: false }));
        if (password.length >= 6) setError((err) => ({ ...err, password: false }));
    }, [email, password]);
    return (
        <div className="login-main-wrapper">
            <div className="login-head">
                <h3 className="f-s-30 urbanist-bold">Log In</h3>
                <p className="f-s-16 manrope-medium mt-2">
                    Start your networking journey today
                </p>
            </div>
            <div className="input-main flex-col gap-22">
                <div>
                    <TextField
                        sx={{ width: "100%" }}
                        id="input-with-icon-textfield"
                        error={error.email}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={emailIcon} className="p-1" alt="email" />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    {error.email && (
                        <p className="error">Invalid or incorrect email ID</p>
                    )}
                </div>
                <div>
                    <TextField
                        sx={{ width: "100%" }}
                        id="input-with-icon-textfield"
                        placeholder="Enter Password"
                        error={error.password}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? "text" : "password"} // This controls the password visibility
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={passwordIcon} className="p-1" alt="password" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div
                                        className="pointer"
                                        onClick={() => setShowPassword((p) => !p)}
                                    >
                                        <img
                                            src={showPassword ? passwordEyeIcon : paswordEyeCloseIcon}
                                            className="p-1"
                                            alt="toggle visibility"
                                        />
                                    </div>
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    {error.password && (
                        <p className="error">Password must be at least 6 characters</p>
                    )}
                </div>
            </div>


            <Button disabled={loading} className={`primary-btn login-button whitespace ${loading && "disabled"}`} onClick={handleLogin}>Log In</Button>

            <p className="pointer text-center"
                onClick={() => setScreen("forgotPassword")}>Forgot password?</p>
        </div>
    );
};

export default Login;
