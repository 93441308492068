import React from "react";
import { getDefaultImgUrl } from "../../../utils/Constant";

const ProfileCard = ({ img, heading, text, action, tag }) => {
  return (
    <div className="profile-card-container m-1 mb-4 ">
      <div className="profile-card-img">
        <img src={img ? img : getDefaultImgUrl(heading)} alt="profile" />
      </div>
      <div className="py-3">
        <h2 className="f-s-14 manrope-semibold pb-2 text-overflow-ellipsis w-100">{heading}</h2>
        <p className="f-s-12 manrope-regular">{text}</p>
      </div>
      {action && <div>{action}</div>}
      {tag && <div className="profile-tag">{tag}</div>}
    </div>
  );
};

export default ProfileCard;
