import React from "react";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../../../utils/commonComponents/forms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDefaultImgUrl } from "../../../utils/Constant";
import { useMediaQuery } from "@mui/material";

const Recommendations = () => {
  const { recommendations } = useSelector((state) => state.HomeState);

  const navigate = useNavigate();
  return (
      <Col className="recommendations-main pb-0">
        <Row>
          <Col>
            <div className="flex-row align-center justify-space-between recommend-head">
              <h2 className="f-s-16 manrope-bold">Recommendations</h2>
              <p className="f-s-12 manrope-regular pointer blue-0E1 underline" onClick={() => navigate("/networking")}>
                View All
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="recommend-container">
              {recommendations.map((recommendation, index) => (
                <RecommendationsCard key={index} recommendation={recommendation} />
              ))}
              {recommendations.length < 1 && (
                <div className="empty-recommend flex-row align-center justify-center py-4 my-4">
                  <p className="f-s-12 manrope-regular">No Recommendations</p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Col>
  );
};

export default Recommendations;

const RecommendationsCard = ({ recommendation }) => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="recommendation-card flex-row justify-space-between ">
          <div className="recommendation-img">
            <img src={recommendation.profileImageUrl ? recommendation.profileImageUrl : getDefaultImgUrl(recommendation.firstName || recommendation.company.companyName)} alt="profile" />
          </div>
        <div className="recommend-detail-wrapper">
          <div className="gap-2 recommend-detail flex-col align-start justify-center h-full">
            <h5 className="f-s-14 manrope-medium">{recommendation.firstName || "New User"}</h5>
            <div className="f-s-12 manrope-regular ">{recommendation.company.companyName}</div>
          </div>
        </div>
      <div className="p-0">
        <div className="text-center h-full flex-row justify-center align-center">
          <CustomButton onClick={() => navigate(`/profile/${recommendation.uuid}`)} className={"common-btn manrope-regular recommendation-btn"}>
            {isMobile?"View Profile":<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.53 11.47C20.6704 11.6107 20.7493 11.8013 20.7493 12C20.7493 12.1988 20.6704 12.3894 20.53 12.53L14.53 18.53C14.4613 18.6037 14.3785 18.6628 14.2865 18.7038C14.1945 18.7448 14.0952 18.7668 13.9945 18.7686C13.8938 18.7704 13.7938 18.7519 13.7004 18.7142C13.607 18.6764 13.5222 18.6203 13.451 18.5491C13.3797 18.4779 13.3236 18.393 13.2859 18.2996C13.2481 18.2062 13.2296 18.1062 13.2314 18.0055C13.2332 17.9048 13.2552 17.8055 13.2962 17.7135C13.3372 17.6215 13.3963 17.5387 13.47 17.47L18.19 12.75H3.99999C3.80108 12.75 3.61031 12.671 3.46966 12.5304C3.32901 12.3897 3.24999 12.1989 3.24999 12C3.24999 11.8011 3.32901 11.6104 3.46966 11.4697C3.61031 11.329 3.80108 11.25 3.99999 11.25H18.19L13.47 6.53003C13.3963 6.46137 13.3372 6.37857 13.2962 6.28657C13.2552 6.19457 13.2332 6.09526 13.2314 5.99455C13.2296 5.89385 13.2481 5.79382 13.2859 5.70043C13.3236 5.60705 13.3797 5.52221 13.451 5.45099C13.5222 5.37977 13.607 5.32363 13.7004 5.28591C13.7938 5.24819 13.8938 5.22966 13.9945 5.23144C14.0952 5.23322 14.1945 5.25526 14.2865 5.29625C14.3785 5.33724 14.4613 5.39634 14.53 5.47003L20.53 11.47Z" fill="black"/>
            </svg>
                }
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
