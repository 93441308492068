import { CLEAR_ERRORS } from "../onboarding/ActionTypes";
import { CONNECT_WEBSOCKET, REFRESH_WEB_SOCKET, DISCONNECT_WEBSOCKET, GET_HOME_BANNERS, GET_HOME_EVENTS, GET_MEMBER_RECOMMENDATIONS, GET_PAST_NOTIFICATIONS, GET_USER_PROFILE, RECEIVE_WEBSOCKET_MESSAGE, SET_MODAL, UPDATE_NOTIFICATION_LIST, WEBSOCKET_ERROR } from "./ActionTypes";
const INITIAL_STATE = {
    modal: null,
    ads: {
        banner: { list: [], length: 0 },
        events: { list: [], length: 0 }
    },
    user: {
    },
    recommendations: [],
    notifications: {
        connected: false,
        messages: [],
        error: null,
        refresh: false
    },
    pastNotifications: {
        sent: [],
        received: [],
        all: []
    }

};
const HomeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MODAL:
            return { ...state, modal: action.payload };
        case GET_HOME_BANNERS:
            return { ...state, ads: { ...state.ads, banner: action.payload } };
        case GET_HOME_EVENTS:
            return { ...state, ads: { ...state.ads, events: action.payload } };
        case GET_USER_PROFILE:
            return { ...state, user: action.payload };
        case GET_MEMBER_RECOMMENDATIONS:
            return { ...state, recommendations: action.payload };
        case CONNECT_WEBSOCKET:
            return { ...state, notifications: { ...state.notifications, connected: true } };
        case RECEIVE_WEBSOCKET_MESSAGE:
            return { ...state, notifications: { ...state.notifications, messages: [...state.notifications.messages, action.payload] } };
        case WEBSOCKET_ERROR:
            return { ...state, notifications: { ...state.notifications, error: action.payload.error } };
        case UPDATE_NOTIFICATION_LIST:
            return { ...state, notifications: { ...state.notifications, messages: action.payload } };
        case DISCONNECT_WEBSOCKET:
            return { ...state, notifications: { ...state.notifications, connected: false } };
        case CLEAR_ERRORS:
            return { ...state, notifications: { ...state.notifications, error: null } };
        case GET_PAST_NOTIFICATIONS:
            return { ...state, pastNotifications: action.payload };
        case REFRESH_WEB_SOCKET:
            return { ...state, notifications: { ...state.notifications, refresh: action.payload, messages: [], } };
        default:
            return state
    }
};
export default HomeReducer