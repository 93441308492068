import React, { useEffect } from "react";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import CustomInput from "../../../utils/commonComponents/forms/CustomInput";
import CustomButton from "../../../utils/commonComponents/forms/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { fetchOptionsforSelect, sendRegisteraionRequest } from "../RegisterAction";
import { toast } from "react-toastify";
import { emailRegex, phoneRegex } from "../../../utils/Constant";
import { useNavigate } from "react-router-dom";

const AddCompany = () => {
    let formData = new FormData();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [addData, setAddData] = React.useState({
        companyName: "",
        contactPerson: "",
        designation: "",
        brochure: "",
        address: "",
        companyDescription: "",
        contactNumber: "",
        email: "",
        gstNo: "",
        incorporationDate: dayjs().format("YYYY-MM-DD"),
        companyRegistrationNumber: "",
        companyType: "",
        capabilitiesAvailable: "",
        tags: [],
        otherAssociation: "",
        companyIndustry: "",
        participate: "",
    });
    const [options, setOptions] = React.useState({
        tags: [],
        companyType: [],
        companyIndustry: [],
        participate: [],
        capabilitiesAvailable: [],
    })
    const { loading } = useSelector(state => state.RegisterState)
    const handleCancel = () => {
        window.location.reload();
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchOptionsforSelect();
                setOptions({
                    tags: response.tags || [],
                    companyType: response.companyType || [],
                    companyIndustry: response.companyIndustry || [],
                    participate: response.participate || [],
                    capabilitiesAvailable: response.capabilitiesAvailable || [],
                });
            } catch (error) {
                toast.error("Failed to load options");
            }
        };
        fetchData();
    }, []);
    const validation = (data) => {
        if (!data.companyName || data.companyName.length > 50 || data.companyName.length < 3) {
            toast.info("Please enter valid company name");
            return false;
        }
        if (!data.contactPerson || data.contactPerson.length > 50 || data.contactPerson.length < 3) {
            toast.info("Please enter contact person");
            return false;
        }
        if (!data.designation || data.designation.length > 50 || data.designation.length < 3) {
            toast.info("Please enter designation");
            return false;
        }
        if (data.brochure && data.brochure.size > 25 * 1024 * 1024) {
            toast.info("Please upload brochure");
            return false;
        }
        if (!data.address || data.address.length > 200) {
            toast.info("Please enter address");
            return false;
        }
        if (!data.companyDescription || data.companyDescription.length > 200) {
            toast.info("Please enter company description");
            return false;
        }
        if (!data.contactNumber || data.contactNumber.length !== 10) {
            toast.info("Please enter contact number");

            return false;
        }
        if (!phoneRegex.test(data.contactNumber)) {
            toast.info("Contact number should be 10 digits");
            return false;
        }
        if (!data.email) {
            toast.info("Please enter email");
            return false;
        }
        if (!emailRegex.test(data.email)) {
            toast.info("Please enter valid email");
            return false;
        }
        if (!data.gstNo) {
            toast.info("Please enter GST number");
            return false;
        }
        if (data.gstNo.length !== 15) {
            toast.info("GST number should be 15 characters");
            return false;
        }
        if (!data.companyRegistrationNumber) {
            toast.info("Please enter company registration number");
            return false;
        }
        if (data.companyRegistrationNumber.length > 50) {

            toast.info("Company registration number should be less than 50 characters");
            return false;
        }
        if (!data.companyType) {
            toast.info("Please enter company type");
            return false;
        }
        if (!data.tags) {
            toast.info("Please select tags");
            return false;
        }
        if (!data.capabilitiesAvailable) {
            toast.info("Please enter capabilities available");
            return false;
        }
        if (!data.companyIndustry) {
            toast.info("Please enter company industry");
            return false;
        }
        if (!data.participate) {
            toast.info("Please enter participation in IPMMI activities");
            return false;
        }
        return true;
    }
    const handleSubmit = () => {
        const valid = validation(addData);
        formData = new FormData()
        if (valid) {
            formData.append("companyName", addData.companyName);
            formData.append("contactPerson", addData.contactPerson);
            formData.append("designation", addData.designation);
            formData.append("brochure", addData.brochure);
            formData.append("address", addData.address);
            formData.append("companyDescription", addData.companyDescription);
            formData.append("phone", addData.contactNumber);
            formData.append("email", addData.email);
            formData.append("gstNo", addData.gstNo);
            formData.append("incorporationDate", addData.incorporationDate);
            formData.append("companyRegistrationNumber", addData.companyRegistrationNumber);
            formData.append("companyTypeUuid", addData.companyType);
            formData.append("companyCapabilitiesUuid", addData.capabilitiesAvailable);
            addData.tags.forEach((tag, i) => {
                formData.append(`tags[${i}]`, tag);
            });
            formData.append("otherAssociation", addData.otherAssociation);
            formData.append("primaryCategoryUuid", addData.companyIndustry);
            formData.append("participationActivitiesUuid", addData.participate);

            dispatch(sendRegisteraionRequest({ formData }));
            navigate("/register/success")
        }
    }
    return (
        <Container className="company-form">
            <Row className="">
                <Col>
                    <h3 className="f-s-24 manrope-bold main-heading">Fill in the details below</h3>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col md={6} className="mb-4 mb-md-0">
                    <div className="me-md-4">
                        <CustomInput
                            value={addData.companyName}
                            onChange={(e) => setAddData({ ...addData, companyName: e.target.value })}
                            important
                            heading={"Company Name"}
                            placeholder={"Enter company name"}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="ms-md-4">
                        <CustomInput
                            value={addData.contactPerson}
                            onChange={(e) => setAddData({ ...addData, contactPerson: e.target.value })}
                            important
                            heading={"Contact Person"}
                            placeholder={"Enter contact person's name"}
                        />
                    </div>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col md={6} className="mb-4 mb-md-0">
                    <div className="me-md-4">
                        <CustomInput
                            value={addData.designation}
                            onChange={(e) => setAddData({ ...addData, designation: e.target.value })}
                            important
                            heading={"Designation"}
                            placeholder={"Enter designation"}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="ms-md-4">
                        <CustomInput
                            heading={"Upload Brochure"}
                            tip={"Only PDF files are allowed"}
                            info={"(max 25MB)"}
                            important={false}
                            onChange={(e) => {
                                setAddData({ ...addData, brochure: e.target.files[0] });
                            }}
                            value={addData.brochure}
                            error={addData.brochure?.size > 25 * 1024 * 1024 && "File size should be less than 25MB currently it is " + Math.ceil(addData.brochure?.size / 1024 / 1024) + "MB"}
                            placeholder={"Choose a PDF Brochure"}
                            type={"file"}
                            accept={".pdf"}
                        />
                    </div>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <CustomInput
                        heading={"Address"}
                        important
                        onChange={(e) => setAddData({ ...addData, address: e.target.value })}
                        value={addData.address}
                        error={addData.address.length > 200 && "Address should be less than 200 characters"}
                        maxLength={300}
                        placeholder={"Enter address (Write 200 characters)"}
                        type={"text-area"}
                        rows={"3"}
                    />
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <CustomInput
                        heading={"Company Introduction"}
                        value={addData.companyDescription}
                        onChange={(e) => setAddData({ ...addData, companyDescription: e.target.value })}
                        error={addData.companyDescription.length > 200 && "Company introduction should be less than 200 characters"}
                        maxLength={300}
                        important
                        placeholder={"Company introduction (Write 200 characters)"}
                        type={"text-area"}
                        rows={"3"}
                    />
                </Col>
            </Row>

            <Row className="mb-4">
                <Col md={6} className="mb-4 mb-md-0">
                    <div className="me-md-4">
                        <CustomInput
                            type={"number"}
                            value={addData.contactNumber}
                            onChange={(e) => setAddData({ ...addData, contactNumber: e.target.value })}
                            important
                            error={(!phoneRegex.test(addData.contactNumber) && addData.contactNumber > 0) && "Contact number should be 10 digits"}
                            heading={"Contact Number"}
                            placeholder={"Enter contact number"}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="ms-md-4">
                        <CustomInput
                            value={addData.email}
                            onChange={(e) => setAddData({ ...addData, email: e.target.value })}
                            important
                            error={!emailRegex.test(addData.email) && addData.email.length > 0 && "Email should be valid"}
                            heading={"Email Address"}
                            placeholder={"Enter email address"}
                        />
                    </div>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col md={6} className="mb-4 mb-md-0">
                    <div className="me-md-4">
                        <CustomInput
                            heading={"GST NO."}
                            value={addData.gstNo}
                            onChange={(e) => setAddData({ ...addData, gstNo: e.target.value })}
                            important
                            error={addData.gstNo.length !== 15 && addData.gstNo && "GST number should be 15 characters"}
                            placeholder={"Enter GST number"}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="ms-md-4">
                        <CustomInput
                            value={addData.incorporationDate}
                            onChange={(e) => setAddData({ ...addData, incorporationDate: e })}
                            important
                            type={"date"}
                            round={"10px"}
                            heading={"Date of Company Incorporation"}
                            placeholder={"Enter date of incorporation"}
                        />
                    </div>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col md={6} className="mb-4 mb-md-0">
                    <div className="me-md-4">
                        <CustomInput
                            value={addData.companyRegistrationNumber}
                            onChange={(e) => setAddData({ ...addData, companyRegistrationNumber: e.target.value })}
                            important
                            error={(addData.companyRegistrationNumber.length > 50 && "Company registration number should be less than 50 characters")}
                            heading={"Company Registration No."}
                            placeholder={"Enter registration number"}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="ms-md-4">
                        <CustomInput
                            type={"dropdown"}
                            value={addData.companyType}
                            onChange={(e) => setAddData({ ...addData, companyType: e })}
                            important
                            options={options.companyType.list?.map((item) => ({ label: item.companyTypeName, value: item.uuid }))}
                            heading={"Type of Company"}
                            placeholder={"Enter type of company"}
                        />
                    </div>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col md={6} className="mb-4 mb-md-0">
                    <div className="me-md-4">
                        <CustomInput
                            heading={"Capabilities Available"}
                            value={addData.capabilitiesAvailable}
                            type={"dropdown"}
                            options={options.capabilitiesAvailable.list?.map((item) => ({ label: item.companyCapabilityName, value: item.uuid })) || []}
                            onChange={(e) => setAddData({ ...addData, capabilitiesAvailable: e })}
                            important
                            placeholder={"Enter capabilities"}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="ms-md-4">
                        <CustomInput
                            type={"dropdown"}
                            multiple
                            important
                            onChange={(e) => setAddData({ ...addData, tags: e })}
                            options={options.tags.list?.map((item) => ({ label: item.tagName, value: item.tagName })) || []}
                            heading={"Tags"}
                            placeholder={"Select tags for company"}
                        />
                    </div>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <CustomInput
                        heading={"Membership/Affiliation"}
                        value={addData.otherAssociation}
                        onChange={(e) => setAddData({ ...addData, otherAssociation: e.target.value })}
                        error={addData.otherAssociation.length > 200 && "Membership/Affiliation should be less than 200 characters"}
                        maxLength={300}
                        important
                        placeholder={"Specify membership/affiliation with any other associations/institutes (Write 200 characters)"}
                        type={"text-area"}
                        rows={"3"}
                    />
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <CustomInput
                        value={addData.companyIndustry}
                        onChange={(e) => setAddData({ ...addData, companyIndustry: e })}
                        important
                        heading={"Industry"}
                        placeholder={"Enter the industry your company primarily caters to"}
                        type={"dropdown"}
                        options={options.companyIndustry.list?.map((item) => ({ label: item.categoryName, value: item.uuid })) || []}
                    />
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <CustomInput
                        heading={"Participation in IPMMI Activities"}
                        placeholder={"Are you willing to participate in the following IPMMI activities?"}
                        type={"dropdown"}
                        value={addData.participate}
                        onChange={(e) => setAddData({ ...addData, participate: e })}
                        important
                        options={options.participate.list?.map((item) => ({ label: item.participationActivities, value: item.uuid })) || []}
                    />
                </Col>
            </Row>

            <Row className="p-3 text-right">
                <Col md="12">
                    <div className="flex-row justify-end">
                        <CustomButton
                            width={"19rem"}
                            color={"bg-blue-0E1 white"}
                            className={"primary-btn flex-row gap-8 align-center justify-center"}
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? <Spinner /> : "Save"}

                        </CustomButton>
                        <CustomButton
                            width={"10rem"}
                            className={"blue-0E1 common-btn bg-transparent "}
                            onClick={handleCancel}

                            disabled={loading}

                        >
                            Cancel

                        </CustomButton>
                    </div>

                </Col>
            </Row>
        </Container>
    );
};

export default AddCompany;
