import React, { useEffect } from "react";
import WestIcon from "@mui/icons-material/West";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPastNotifications, readNotification, refreshWebSocketNotifications, showModal } from "../../../app/home/HomeAction";
import CustomTabs from "../common/Tabs";
import noNotification from "../../../assets/images/no-notification.svg";
import CustomButton from "../forms/CustomButton";
import BasicModal from "../Modal";
import accepticon from "../../../assets/images/accept.svg";
import rejecticon from "../../../assets/images/reject.svg";
import CustomInput from "../forms/CustomInput";
import requestsentIcon from "../../../assets/images/request-sent.svg";
import IPMMIlogo from "../../../assets/images/ipmmi-logo.svg"
import SentNotificatons from "./SentNotificatons";
import ReceivedNotifications from "./ReceivedNotifications";
import { toast } from "react-toastify";
import postLoginInstance from "../../PostLoginAxios";
import { getDefaultImgUrl } from "../../Constant";
import dayjs from "dayjs";

const Notification = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const [invite, showInvite] = React.useState(false);
  const [resechdule, showResechdule] = React.useState(false);
  const [requestsent, showRequestsent] = React.useState(false);

  let { messages } = useSelector(state => state.HomeState.notifications);
  const { pastNotifications } = useSelector(state => state.HomeState);
  const uniqueMessages = [...new Map(messages.map((item) => [item.id, item])).values()];
  const { sent, received, all } = pastNotifications;
  const uniqueAllMessages = [...new Map(all.map((item) => [item.id, item])).values()];
  useEffect(() => {
    dispatch(getPastNotifications());
  }, [dispatch]);

  useEffect(() => {
    return () => {

      dispatch(readNotification({ notificationUuid: uniqueMessages.map((message) => message.uuid) }));
    }
  }, []);
  const getImg = (message) => {
    if (message.notificationType === "sent_invite") {
      const receiverUser = message.meeting.receiverUser;
      if (receiverUser?.company) {
        return <img src={receiverUser?.company?.profileImageUrl || getDefaultImgUrl(receiverUser?.company?.companyName || "")} alt="admin" />;
      }
      return <img src={receiverUser?.members?.profileImageUrl || getDefaultImgUrl(receiverUser?.members?.firstName) || ""} alt="admin" />;
    }
    if (message.notificationType === "approval") {
      const receiverUser = message.meeting.receiverUser;
      if (receiverUser?.company) {
        return <img src={receiverUser?.company?.profileImageUrl || getDefaultImgUrl(receiverUser?.company?.companyName || "")} alt="admin" />;
      }
      return <img src={receiverUser?.members?.profileImageUrl || getDefaultImgUrl(receiverUser?.members?.firstName) || ""} alt="admin" />;
    }
    if (message.notificationType === "reschedule_invite") {
      const receiverUser = message.meeting.receiverUser;
      if (receiverUser?.company) {
        return <img src={receiverUser?.company?.profileImageUrl || getDefaultImgUrl(receiverUser?.company?.companyName || "")} alt="admin" />;
      }
      return <img src={receiverUser?.members?.profileImageUrl || getDefaultImgUrl(receiverUser?.members?.firstName) || ""} alt="admin" />;
    }
    if (message.notificationType === "meeting") {
      const senderUser = message.meeting.senderUser;
      if (senderUser.company) {
        return <img src={senderUser?.company?.profileImageUrl || getDefaultImgUrl(senderUser.company.companyName || "")} alt="admin" />;
      }
      return <img src={senderUser?.members?.profileImageUrl || getDefaultImgUrl(senderUser.members?.firstName) || ""} alt="admin" />;
    }
    if (message.notificationType === "super_admin") {
      return <img src={IPMMIlogo} alt="admin" />;
    }
    else {
      return <img src={message?.meeting?.senderUser?.company?.profileImageUrl || message?.meeting?.senderUser?.members?.profileImageUrl || getDefaultImgUrl("")
      } alt="logo" />;
    }
  }

  const handleWithdraw = async (message) => {
    try {
      const params = { meetingUuid: message.meeting.uuid }
      await postLoginInstance.delete("/meetings", { params });
      toast.success("Invite Withdrawn");
      dispatch(refreshWebSocketNotifications());
    } catch (error) {
      toast.error("Something went wrong");
    }
    dispatch(showModal(null));
  }

  useEffect(() => {
    dispatch(refreshWebSocketNotifications());
  }, [dispatch]);

  const tabOptions = [
    {
      eventKey: "all",
      title: "All",
      children: (
        (uniqueAllMessages.length > 0 || uniqueMessages.length > 0) ? <>
          {uniqueMessages.length > 0 && uniqueMessages.map((message, index) => (
            <NotificationCard
              invite={invite}
              showInvite={showInvite}
              data={{
                recent: true,
                img: getImg(message),
                message: (message.notificationType === "super_admin" && message.message),
                title: (message.title),
                action: (
                  <>
                    {message.notificationType === "super_admin" &&
                      <></>}
                    {(message.notificationType === "meeting" || message.notificationType === "reschedule") &&
                      <><CustomButton onClick={() => showInvite(message)} className={"primary-btn"}>View Invite</CustomButton></>}
                    {message.notificationType === "sent_invite" &&
                      <><CustomButton onClick={() => handleWithdraw(message)} className={"primary-btn bg-grey-F6F black"}>Withdraw</CustomButton></>}

                  </>
                ),
              }}
            />
          ))}
          {
            uniqueAllMessages.length > 0 && uniqueAllMessages.map((message, index) => (
              <NotificationCard
                invite={invite}
                showInvite={showInvite}
                data={{
                  recent: false,
                  img: getImg(message),
                  message: (message.notificationType === "super_admin" && message.message),
                  title: message.title,
                  action: (
                    <>
                      {message.notificationType === "super_admin" &&
                        <></>}
                      {(message.notificationType === "meeting" || message.notificationType === "reschedule") &&
                        <><CustomButton onClick={() => showInvite(message)} className={"primary-btn"}>View Invite</CustomButton></>}
                      {message.notificationType === "sent_invite" &&
                        <><CustomButton onClick={() => handleWithdraw(message)} className={"primary-btn bg-grey-F6F black"}>Withdraw</CustomButton></>}

                    </>
                  ),
                }}
              />
            ))
          }
        </> : null
      ),
    },
    {
      eventKey: "sent",
      title: "Sent",
      children: (
        sent.length > 0 ? <SentNotificatons invite={invite} showInvite={showInvite} getImg={getImg} handleWithdraw={handleWithdraw} /> : null
      ),
    },
    {
      eventKey: "received",
      title: "Received",
      children: (
        received.length > 0 ? <ReceivedNotifications invite={invite} showInvite={showInvite} getImg={getImg} handleWithdraw={handleWithdraw} /> : null
      ),
    },
  ];
  return (
    <>
      {invite && (
        <BasicModal
          className={"notification-invite-modal"}
          hideCloseButton={isMobile}
          closeOnBackdropClick={true}
          open={invite}
          handleClose={() => showInvite(false)}
        >
          <InviteModal
            next={() => {
              showResechdule(true);
            }}
            data={invite}
            handleClose={() => showInvite(false)}
          />
        </BasicModal>
      )}
      {resechdule && (
        <BasicModal
          className={"notification-invite-modal"}
          open={resechdule}
          hideCloseButton
          closeOnBackdropClick
          handleClose={() => showResechdule(null)}
        >
          <ResechduleModal
            back={() => {
              showResechdule(false);
            }}
            meetingUuid={invite?.meeting.uuid}
            notificationUuid={invite?.uuid}
            next={() => {
              showRequestsent(true);
              showResechdule(false);
              dispatch(showModal(null));
            }}
          />
        </BasicModal>
      )}
      {requestsent && (
        <BasicModal
          className={"notification-invite-modal"}
          open={requestsent}
          closeOnBackdropClick
          handleClose={() => showRequestsent(null)}
        >
          <RequestSentModal />
        </BasicModal>
      )}
      <div>
        <div>
          <h3 className="f-s-16 manrope-bold flex-row gap-12 justify-start align-center">
            {isMobile && (
              <div onClick={() => dispatch(showModal(null))}>
                <WestIcon />
              </div>
            )}
            Notifications
          </h3>
        </div>
        <div className="my-2 px-0 tabs">
          <CustomTabs
            options={tabOptions}
            nullText={"No Notifications in past week"}
            nullImg={noNotification}
          ></CustomTabs>
        </div>
      </div>
    </>
  );
};

export default Notification;

// cards for notifications

export const NotificationCard = ({ data }) => {
  return (
    <div className="notification-card">
      <div className="flex-row notification-main gap-10 justify-space-between align-center">
        <div className="flex-row gap-10 justify-space-between align-center">
          <div className="notification-img">
            {data?.img}
          </div>
          <div>
            <h3 className="f-s-12 manrope-regular">{data?.title}</h3>
            {data.message && <p className="f-s-12 manrope-medium">{data?.message}</p>}
          </div>
        </div>
        {data.action && (
          <div>
            <p className="f-s-12 manrope-medium">{data?.action}</p>
          </div>
        )}
      </div>
      <hr className={data.recent ? "border-blue-0E1 border-1" : ''} />
    </div>
  );
};

// sub modals

const InviteModal = ({ handleClose, next, data }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();

  const handleInvite = async (invite) => {
    try {
      const params = {
        meetingUuid: invite.meetingUuid,
      }
      await postLoginInstance.patch("/meetings/handle-approval", { approve: invite.approve, notificationUuid: invite.notificationUuid }, { params });
      invite.approve ? toast.success("Meeting Approved") : toast.info("Meeting Rejected");
      dispatch(refreshWebSocketNotifications());
    } catch (error) {
      toast.error("Something went wrong");
    }
    handleClose();
    dispatch(showModal(null));
  };
  return (
    <div>
      <div className="flex-row gap-10 align-center">
        {isMobile && (
          <div onClick={() => handleClose()}>
            <WestIcon />
          </div>
        )}
        <h3 className="f-s-14 manrope-bold">Invite from {data.meeting.senderUser.company?.companyName || `${data.meeting?.senderUser?.members?.firstName} ${data.meeting?.senderUser?.members?.lastName}`}</h3>
      </div>
      {data.notificationType === "meeting" && <div className="my-4">
        <h6 className="f-s-12 manrope-semibold mb-2">Message</h6>
        <p className="f-s-12 manrope-regular">
          {data.message}
        </p>
      </div>}
      <div className="my-4">
        <h6 className="f-s-12 manrope-semibold mb-2">Meeting Agenda</h6>
        <p className="f-s-12 manrope-regular">
          {data.meeting.meetingAgenda}
        </p>
      </div>
      <div className="my-4">
        <h6 className="f-s-12 manrope-semibold mb-2">Date and time</h6>
        <p className="f-s-12 manrope-regular">{dayjs(data.date).format("DD MMMM YYYY, hh:MM a")}</p>
      </div>
      <div className="flex-row justify-space-between align-center mb-3">
        <div className="flex-row gap-10">
          <div className="round-chip bg-green-00B-50 pointer">
            <img src={accepticon} onClick={() => handleInvite({ approve: true, meetingUuid: data.meeting.uuid, notificationUuid: data.uuid })} alt="" />
          </div>
          <div className="round-chip bg-red-F25-50 pointer">
            <img src={rejecticon} onClick={() => handleInvite({ approve: false, meetingUuid: data.meeting.uuid, notificationUuid: data.uuid })} alt="" />
          </div>
        </div>
        {data.notificationType === "meeting" && <div
          className="pointer"
          onClick={() => {
            next();
          }}
        >
          <p className="f-s-12 manrope-regular blue-0E1 underline">
            Reschedule
          </p>
        </div>}
      </div>
    </div>
  );
};

const ResechduleModal = ({ back, next, notificationUuid, meetingUuid }) => {
  const [data, setData] = React.useState({
    date: dayjs(),
    meetingAgenda: ""
  })
  const dispatch = useDispatch();

  const handleReschdule = async () => {
    try {
      if (!data.meetingAgenda || data.meetingAgenda.length < 5 || data.meetingAgenda.length > 150) {
        return toast.info("Meeting Agenda should be between 5 to 150 characters");
      }
      if (!data.date) {
        return toast.info("Please select a date and time");
      }
      const params = { meetingUuid }
      await postLoginInstance.patch("/meetings/reschedule", { notificationUuid, meetingAgenda: data.meetingAgenda, date: data.date.toISOString() }, { params });
      next()
      dispatch(refreshWebSocketNotifications());
    }
    catch (error) {
      toast.error("Something went wrong");
      dispatch(showModal(null));
    }
  }
  return (
    <div className="reschedule-modal">
      <div className="flex-row gap-10 align-center">
        <div className="pointer flex-row align-center" onClick={() => back()}>
          <WestIcon />
        </div>
        <h3 className="f-s-14 manrope-bold">Reschedule Meeting</h3>
      </div>
      <div className="reschedule-input-box my-4">
        <CustomInput
          type={"text-area"}
          value={data.meetingAgenda}
          onChange={(e) => setData({ ...data, meetingAgenda: e.target.value })}
          error={data.meetingAgenda && (data.meetingAgenda.length < 5 || data.meetingAgenda.length > 150) && "Meeting Agenda should be between 5 to 150 characters"}
          heading={"Message"}
          placeholder={
            "What will you like to discuss in the meeting?(In 100 to 150 Words)"
          }
        ></CustomInput>
        <CustomInput
          type={"date-time"}
          value={data.date}
          onChange={(e) => setData({ ...data, date: e })}
          heading={"Date and time"}
          placeholder={"Select Date and time"}
        ></CustomInput>
      </div>
      <div className="text-center">
        <CustomButton className={"common-btn"} onClick={handleReschdule}>
          Submit
        </CustomButton>
      </div>
    </div>
  );
};
const RequestSentModal = () => {
  return (
    <div className="request-sent-modal">
      <div className="flex-col gap-10 justify-center align-center p-4">
        <img src={requestsentIcon} alt="" />
        <p className="f-s-24 manrope-semibold">Request Sent</p>
        <p className="f-s-12 manrope-regular px-4 text-center">
          Your request has been sent to the member. They will review it and may
          choose to accept, reject, or request a reschedule according to their
          availability. You'll be notified once they respond
        </p>
      </div>
    </div>
  );
};
