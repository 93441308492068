import React, { useEffect } from "react";
import CompanyProfile from "./ProfileCard";
import CustomButton from "../../../utils/commonComponents/forms/CustomButton";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { West } from "@mui/icons-material";
import { showModal } from "../../home/HomeAction";
import { getDefaultImgUrl, modals } from "../../../utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../../utils/commonComponents/Modal";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import PeopleCard from "./PeopleCard";

const Main = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { modal, user } = useSelector((state) => state.HomeState);

  const downloadFile = async ({ url, fileName }) => {
    // The URL of the file you want to download
    const fileUrl = url;

    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName || "download";
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      window.open(fileUrl, "_blank");
    }
  };
  return (
    <>
      {modal === modals.EDIT_COMPANY_PROFILE_MODAL && (
        <BasicModal
          open={modals.EDIT_COMPANY_PROFILE_MODAL}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          className={"edit-member-modal"}
        />
      )}
      {modal === modals.EDIT_PROFILE_OPTIONS_MODAL && (
        <BasicModal
          open={modal}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          className={"edit-member-modal"}
        />
      )}
      {modal === modals.EDIT_PASSWORD_MODAL && (
        <BasicModal
          open={modal}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          className={"edit-member-modal"}
        />
      )}
      <div className="my-profile-container ">
        {!isMobile && (
          <div className="networking-header flex-row gap-12 align-center mb-3">
            <div>
              <h1 className="f-s-24 manrope-bold">Profile</h1>
            </div>
          </div>
        )}
        <CompanyProfile
          img={user?.profileImageUrl || getDefaultImgUrl(user?.companyName)}
          heading={user?.companyName}
          text={`${user?.contactPerson} | ${user?.designation}`}
          phone={user?.phone}
          email={user?.email}
          background={user?.bannerUrl}
          edit={true}
        ></CompanyProfile>
        <div className="company-details-container">
          <Row>
            <Col md="7">
              <Row>
                <Col className="my-1">
                  <h3 className="f-s-16 manrope-medium">About Company</h3>
                  <p className="f-s-14 my-2 manrope-regular">
                    {user?.companyDescription}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs="6" md="3" className="my-2">
                  <h3 className="f-s-16 manrope-medium">Primary Contact</h3>
                  <p className="f-s-14 my-2 manrope-regular">
                    {user?.contactPerson}
                  </p>
                </Col>
                <Col xs="6" md="3" className="my-2">
                  <h3 className="f-s-16 manrope-medium">
                  Incorportaion  Date
                                    </h3>
                  <p className="f-s-14 my-2 manrope-regular">
                    {dayjs(user?.incorporationDate).format("DD-MM-YYYY")}
                  </p>
                </Col>
                <Col xs="6" md="3" className="my-2">
                  <h3 className="f-s-16 manrope-medium">Designation</h3>
                  <p className="f-s-14 my-2 manrope-regular">
                    {user?.designation}
                  </p>
                </Col>
                <Col xs="6" md="3" className="my-2">
                  <h3 className="f-s-16 manrope-medium">GST No.</h3>
                  <p className="f-s-14 my-2 manrope-regular">{user?.gstNo}</p>
                </Col>
                <Col xs="6" md="3" className="my-2">
                  <h3 className="f-s-16 manrope-medium">Company Reg. No.</h3>
                  <p className="f-s-14 my-2 manrope-regular">
                    {user?.companyRegistrationNumber}
                  </p>
                </Col>
                <Col xs="6" md="3" className="my-2">
                  <h3 className="f-s-16 manrope-medium">Type of Company</h3>
                  <p className="f-s-14 my-2 manrope-regular">
                    {user?.companyType.companyTypeName}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md="3"></Col>
            <Col md="2">
              <div className="company-profile-buttons flex-col gap-25">
                <CustomButton
                  onClick={() =>
                    dispatch(showModal(modals.EDIT_PROFILE_OPTIONS_MODAL))
                  }
                  className={"primary-btn"}
                >
                  Edit Profile
                </CustomButton>
                {user.brochureUrl && (
                  <CustomButton
                    onClick={() =>
                      downloadFile({
                        url: user?.brochureUrl,
                        fileName: user?.companyName,
                      })
                    }
                    className={"primary-btn bg-grey-F2F black"}
                  >
                    Brochure
                  </CustomButton>
                )}
              </div>
            </Col>
          </Row>
        </div>

        <div className="associated-members">
          {user?.members?.length > 0 ? (
            <Row>
              <Col>
                <h3 className="f-s-16 manrope-bold mb-4">People</h3>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <div className="flex-col gap-4">
              {user?.members?.map((member) => {
                return (
                  <Col md="5" sm="12">
                    <PeopleCard
                      uuid={member.uuid}
                      name={`${member.firstName || "New User"} ${
                        member.lastName || ""
                      }`}
                      img={member.profileImageUrl}
                    ></PeopleCard>
                  </Col>
                );
              })}
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Main;
